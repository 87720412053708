.footer {
  padding: 20px;
  background-color: #221a4b;
  text-align: center;
  margin-top: auto;


  &__container {
    justify-content: space-between;
    display: flex;
    flex-flow: row wrap;
  }

  &__copy {
    font-size: 12px;
    color: white;
    text-align: center;
    padding-top: 17px;
  }
}

@media screen and (max-width: 700px) and (min-width: 0) {
  .footer {
    &__container {
      justify-content: center;
    }
  }
}