.header {
	display:         flex;
	align-items:     center;
	flex-flow:       row wrap;
	justify-content: space-between;
	padding:         14px 0;
	text-align:      center;

}

@media screen and (max-width: 700px) and (min-width: 0) {
	.header {
		justify-content: center;
	}
}