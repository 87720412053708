@import "../variables";

.profile {
	padding-bottom:   $main-gap;
	color:            white;
	background-color: #402978;

	&__header {
		font-size:        1.4rem;
		margin:           0;
		padding:          12px 18px;
		color:            #fa3060;
		background-color: #5b3f9e;
	}

	&__h3 {
		font-size:      1.4rem;
		margin:         0;
		padding-bottom: .5em;
		text-transform: uppercase;
		color:          #fa3060;
	}

	&__container {
		display:   flex;
		flex-flow: row wrap;
		//margin:    -15px;
		padding:   10px 20px 20px;

		@media screen and (max-width: 550px) and (min-width: 0) {
			justify-content: center;
		}
	}

	&__photo {
		width:            110px;
		height:           110px;
		margin:           15px;
		border-radius:    50%;
		background-color: #6647b3;
	}

	&__info {
		margin: 15px;
	}

	&__value {
		color: #fa3060;
	}

	&__links {
		display:        flex;
		flex-flow:      row nowrap;
		text-transform: uppercase;
	}

	&__link-item {
		flex:             1 1 200px;
		margin:           3px;
		padding:          15px 20px;
		text-align:       center;
		color:            #8377a5;
		background-color: #483083;

		&:hover {
			background-color: #5b3f9e;
			color:            white;
			text-decoration: none;
		}
	}

	&__coin {
		width: 50px;
	}
}