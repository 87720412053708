@font-face {
	font-family: 'RobotoRegular';
	src: url('../fonts/Roboto-Regular_1.ttf');
}

@font-face {
	font-family: 'RobotoBold';
	src: url('../fonts/RobotoBold.ttf');
}

@font-face {
	font-family: 'RobotoCondBold';
	src: url('../fonts/RobotoCondensed-Bold_1.ttf');
}

@font-face {
	font-family: 'RobotoCondRegular';
	src: url('../fonts/RobotoCondensedRegular.ttf');
}
@font-face {
	font-family: 'RobotoMedium';
	src: url('../fonts/Roboto-Medium_1.ttf');
}