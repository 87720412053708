.case {
	font-size:           14px;
	line-height:         1.4;
	position:            relative;
	flex:                1 1 280px;
	max-width:           380px;
	height:              155px;
	margin:              5px;
	padding:             13px 20px;
	color:               #5c83d6;
	border-radius:       3px;
	background-color:    #402978;
	background-image:    url('../img/case-background.png');
	background-position: center;
	background-size:     cover;

	&__coin {
		position: absolute;
		top:      calc(50% - 32px);
		left:     calc(50% - 50px);
	}

	&__export {
		line-height: 1.2;
	}

	&__price {
		position:   absolute;
		top:        13px;
		right:      13px;
		text-align: right;
	}

	&__price-count {
		font-size: 18.2px;
		color:     #ec2f63;
	}

	&__header {
		font-size:      18.2px;
		padding-bottom: 2px;
		color:          white;
	}

	&__button {
		font-size:      0.9em;
		position:       absolute;
		right:          0;
		bottom:         14px;
		padding:        13px 6px 14px 16px;
		text-transform: uppercase;
		border-radius:  3rem 0 0 3rem;
	}

	&__range {
		position: absolute;
		bottom:   14px;
	}
}