.prizes-board {
  display: inline-block;
  //display:     flex;
  //flex-flow:   row wrap;
  //align-items: center;
  //margin:      -5px;
  //flex:        1 1 auto;
  overflow: hidden;
  white-space: nowrap;

  &__item {
    display: inline-block;
    width: 67px;
    height: 67px;
    margin: 5px 4px;
    text-align: center;
    border-radius: 3px;
    background-color: #f9700b;
    background-image: url(../img/sunburst.png);
    background-position: center;
    background-size: cover;
  }

  &__coin {
    width: 55px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 550px) and (min-width: 0) {
  .prizes-board {
    display: none;
  }
}