$font-1: 'Montserrat', sans-serif;
$font-2: 'Plumpfull', sans-serif;

$color-white: #ffffff;
$color-black: #000000;
$color-1: #7d6eff;
$color-2: #faf9fb;
$color-3: #786ffe;
$color-4: #edecf2;
$color-5: #6f66f6;
$color-6: #858392;
$color-7: #e272ac;
$color-8: #e7e6e9;
$color-9: #cccccc;

$color-text-white: #ffffff;
$color-text-1: #686672;
$color-text-2: #716f7c;
$color-text-3: #858391;
$color-text-4: #786ffe;
$color-text-5: #202020;
$color-text-6: #7d6eff;
$color-text-7: #7b6cfd;
$color-text-8: #2b2b2b;
$color-text-9: #6963ec;
$color-text-10: #edecf2;

$main-gap: 2rem;
$small-gap: 5px;
