.prizes-live {
	font-family:      "Impact", sans-serif;
	font-size:        2em;
	line-height:      1.1;
	position:         relative;
	display:          inline-block;
	//display:          flex;
	//width: 100px;
	//flex:             0 0 99px;
	//flex-flow:        column nowrap;
	//justify-content:  center;
	//height:           95px;
	margin:           -13px 0;
	padding:          20px 8px;
	color:            white;
	background-color: #f16c00;
	box-shadow: 5px 7px 10px 0 rgba(0, 0, 0, 0.4);

	&:before, &:after {
		position:     absolute;
		left:         -10px;
		content:      '';
		border-style: solid;
		border-color: transparent;
	}

	&:before {
		top:                0;
		border-width:       13px 10px 0 0;
		border-right-color: #5a4200;
	}

	&:after {
		bottom:             0;
		border-width:       0 10px 13px 0;
		border-right-color: #5a4200;
	}

	&__online {
		&:after {
			content: "";
			display: inline-block;
			border-radius: 50%;
			margin-left: .25em;
			width: .8em;
			height: .8em;
			background-color: #fdce0f;
		}
	}
}

@media screen and (max-width: 550px) and (min-width: 0) {
	.prizes-live {
		display: none;
	}
}